// src/components/MenuEpicEvils.jsx

import React, { useState, useRef, useEffect } from "react";
import { menuItems } from "../data/menuItems.js"; // Importamos los elementos del menú

const MenuIcon = () => (
  <svg
    height="21"
    viewBox="0 0 21 21"
    width="21"
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor" // Usamos currentColor para heredar el color del texto
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m4.5 7.5h12" />
      <path d="m6.5 10.5h8" />
      <path d="m8.5 13.5h4" />
    </g>
  </svg>
);

const XIcon = () => (
  <svg
    height="21"
    viewBox="0 0 21 21"
    width="21"
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor" // Usamos currentColor para heredar el color del texto
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="10.5" cy="10.5" r="8" />
      <path d="m6.5 8.5h8" />
      <path d="m8.5 10.5h4" />
      <path d="m9.5 12.5h2" />
    </g>
  </svg>
);

const ArrowDownIcon = () => (
  <svg
    className="w-4 h-4 transform group-open:rotate-180 transition-transform duration-300"
    fill="none"
    stroke="currentColor" // Usamos currentColor para heredar el color del texto
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 9l-7 7-7-7"
    />
  </svg>
);

const MenuEpicEvils = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const timeoutRef = useRef(null);

  const handleMouseEnter = (index) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setActiveMenu(index);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setActiveMenu(null);
    }, 200); // Retraso de 200ms antes de ocultar el submenú
  };

  useEffect(() => {
    // Añadir o eliminar la clase 'overflow-hidden' al <body>
    if (isMobileMenuOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    // Limpieza al desmontar el componente
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isMobileMenuOpen]);

  return (
    <nav className="bg-footer-bg border-b border-footer-border px-4 py-3 shadow-sm fixed w-full z-50">
      <div className="max-w-7xl mx-auto flex items-center justify-between">
        {/* Logo */}
        <div className="flex-shrink-0">
          <a
            href="https://epicevils.com"
            className="font-ryomen text-footer-logo text-logo"
          >
            EpIC EVILs
          </a>
        </div>

        {/* Menú Principal y Login/Signup para pantallas grandes */}
        <div className="hidden md:flex items-center justify-center space-x-8">
          {/* Menú Principal */}
          <ul className="flex space-x-8">
            {menuItems.map((item, index) => (
              <li
                key={index}
                className="relative"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                aria-haspopup="true"
                aria-expanded={activeMenu === index ? "true" : "false"}
              >
                <a
                  href={item.link}
                  className="text-footer-link hover:text-footer-link-hover transition-colors duration-300 font-medium py-2 text-menu"
                >
                  {item.title}
                </a>
                {/* Submenú */}
                {item.submenu && (
                  <ul
                    className={`absolute left-0 top-full mt-0 w-48 bg-white border border-footer-border rounded-md shadow-lg ${
                      activeMenu === index ? "block animate-fadeIn" : "hidden"
                    }`}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    role="menu"
                  >
                    {item.submenu.map((subitem, subIndex) => (
                      <li key={subIndex}>
                        <a
                          href={subitem.link}
                          className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-footer-link-hover transition-colors duration-200 text-menu"
                        >
                          {subitem.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* Login y Signup */}
        <div className="hidden md:flex items-center space-x-4">
          <a
            href="https://deckmaker.tcgse.com/login"
            className="text-footer-link hover:text-footer-link-hover transition-colors duration-300 text-menu"
          >
            Login
          </a>
          <a
            href="https://deckmaker.tcgse.com/signup"
            className="px-4 py-2 bg-footer-logo text-footer-bg font-bold text-menu rounded-md hover:bg-footer-link-hover transition-colors duration-300"
          >
            Signup
          </a>
        </div>

        {/* Botón de menú móvil */}
        <div className="md:hidden">
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="text-footer-link hover:text-footer-link-hover focus:outline-none focus:text-footer-link-hover"
            aria-label="Toggle menu"
          >
            {isMobileMenuOpen ? <XIcon /> : <MenuIcon />}
          </button>
        </div>
      </div>

      {/* Menú Móvil */}
      {isMobileMenuOpen && (
        <div className="md:hidden mt-4">
          <ul className="flex flex-col space-y-4">
            {menuItems.map((item, index) => (
              <li key={index} className="relative">
                <details className="group">
                  <summary className="flex justify-between items-center cursor-pointer text-footer-link hover:text-footer-link-hover transition-colors duration-300 font-medium px-2 py-1 text-menu">
                    {item.title}
                    {item.submenu && <ArrowDownIcon />}
                  </summary>
                  {item.submenu && (
                    <ul className="mt-2 ml-4 flex flex-col space-y-2 max-h-60 overflow-y-auto">
                      {item.submenu.map((subitem, subIndex) => (
                        <li key={subIndex}>
                          <a
                            href={subitem.link}
                            className="block px-2 py-1 text-footer-link hover:bg-gray-100 hover:text-footer-link-hover transition-colors duration-200 rounded-md text-menu"
                          >
                            {subitem.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </details>
              </li>
            ))}

            {/* Separator */}
            <li className="border-t border-footer-border"></li>

            {/* Login y Signup en móvil */}
            <li className="flex flex-col space-y-2">
              <a
                href="https://deckmaker.tcgse.com/login"
                className="text-footer-link hover:text-footer-link-hover transition-colors duration-300 font-medium px-2 py-1 text-menu"
              >
                Login
              </a>
              <a
                href="https://deckmaker.tcgse.com/signup"
                className="w-full text-center px-4 py-2 bg-footer-logo text-footer-bg font-bold text-menu rounded-md hover:bg-footer-link-hover transition-colors duration-300"
              >
                Signup
              </a>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default MenuEpicEvils;
