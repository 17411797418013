// src/data/menuItems.js

export const menuItems = [
  {
    title: "Club",
    link: "#",
    submenu: [
      { title: "Team", link: "https://www.epicevils.com/team" },
      { title: "Clothes", link: "https://www.epicevils.com/clothes" },
      { title: "Standings", link: "https://www.epicevils.com/standings" },
      { title: "Contact", link: "https://www.epicevils.com/contact" },
    ],
  },
  {
    title: "Tools",
    link: "#",
    submenu: [
      /*
      { title: "Leagues", link: "https://leagues.epicevils.com/" },
      { title: "PlayTools", link: "https://play.epicevils.com/" },
      */
      { title: "TCGSE", link: "https://www.tcgse.com/" },
      { title: "DeckMaker", link: "https://deckmaker.tcgse.com/" },
    ],
  },
  /*
  {
    title: "Initiatives",
    link: "#",
    submenu: [
      { title: "TCG Awards", link: "https://www.tcgawards.com/" },
      { title: "Misty Tears", link: "https://mistytears.com/" },
    ],
  }, 
  */
  {
    title: "News",
    link: "#",
    submenu: [
      { title: "Blog", link: "https://www.tcgse.com/blog" },
      { title: "PressRoom", link: "https://news.pencil.li/" },
      { title: "Twitter", link: "https://x.com/epicevils" },
    ],
  },
  {
    title: "Collabs",
    link: "#",
    submenu: [{ title: "inZoi", link: "https://www.epicevils.com/collab" }],
  },
];
