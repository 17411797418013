// src/components/Footer.jsx

import React from "react";
import { menuItems } from "../data/menuItems.js"; // Importamos los elementos del menú

const Footer = () => {
  return (
    <footer className="bg-footer-bg text-white py-8">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between">
          {/* Logo */}
          <div className="mb-6 md:mb-0 flex flex-col items-center md:items-start">
            <a
              href="https://epicevils.com"
              className="text-logo font-ryomen text-footer-logo"
            >
              EpIC EVILs
            </a>

            {/* Separador */}
            <div className="border-t border-footer-border my-4 w-full"></div>

            {/* Twitter */}
            <a
              href="https://twitter.com/epicevils"
              className="flex items-center hover:underline hover:text-white transition-colors duration-200"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 1200 1227"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                  fill="#FF758F"
                />
              </svg>
            </a>
          </div>

          {/* Enlaces del Footer */}
          <div className="flex flex-col sm:flex-row sm:space-x-12">
            {menuItems.map((item, index) => (
              <div key={index} className="mb-4 sm:mb-0">
                <h4 className="text-footer-title text-lg font-semibold mb-2">
                  {item.title}
                </h4>
                <ul>
                  {item.submenu.map((subitem, subIndex) => (
                    <li key={subIndex}>
                      <a
                        href={subitem.link}
                        className="hover:underline text-footer-link hover:text-white transition-colors duration-200"
                        target={
                          subitem.title === "PressRoom" ||
                          subitem.title === "Twitter"
                            ? "_blank"
                            : "_self"
                        }
                        rel={
                          subitem.title === "PressRoom" ||
                          subitem.title === "Twitter"
                            ? "noreferrer"
                            : ""
                        }
                      >
                        {subitem.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Separador */}
        <div className="border-t border-footer-border mt-8 pt-4">
          {/* Derechos de autor */}
          <p className="text-center text-footer-copy text-sm">
            &copy; {new Date().getFullYear()}{" "}
            <a
              href="https://www.epicevils.com"
              className="hover:underline text-footer-logo hover:text-white transition-colors duration-200 font-ryomen ml-1"
            >
              EpIC EVILs
            </a>
            <span className="text-footer-copy">. All rights reserved.</span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
